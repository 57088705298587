import { all, takeLatest, select, put, call } from 'redux-saga/effects'
import Lodash from 'lodash'
import LogHelper from 'ui-tdm-app/utils/logger'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import {
	getCoreEndPoint,
	getPlantationEndPoint,
	getIAMEndPoint,
	getMarketplaceEndPoint,
	getTraceabilityEndPoint,
	getMirrorNodeBaseUrl,
	getExplorerBaseurl,
	getUtilitiesEndPoint,
} from 'ui-tdm-app/config'
import request from 'ui-tdm-app/utils/request'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
	getUserProfileFromLoggedUser,
	fetchOrgsDetails,
	fetchCurrentOrgsDetail,
} from 'ui-tdm-app/modules/Auth/AuthSaga'
import { fetchDOUploadStatus } from 'ui-tdm-app/modules/Admin/AdminSaga'
import { createWeighBridgeSlip } from 'ui-tdm-app/modules/WeighBridge/WeighBridgeSaga'
import querySerializer from 'query-string'
import { getIn, omit, merge, addLast, setIn } from 'timm'
import {
	isEmptyObject,
	pick,
	pickBy,
	getQuantityWithUnit,
} from 'ui-tdm-app/utils/helpers'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { currentPlatform } from 'ui-lib/utils/config'
import { Toast } from 'ui-lib/components/Toast'
import { TradeDocDuc } from './duc'
import {
	INITIAL_TYPES,
	excludedReadableSections,
	ROLE_ACCESSES,
	getActiveTypesBasedOnRole,
} from './config'
import {
	transformSortStringsToBEQueries,
	extractOrgIDsFromResponses,
	responseDocsMapper,
	extractSortQueries,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	weighbridgeData,
	ffbQualityData,
	cpoQualityData,
	otherAttachmentData,
	billOfLadingAttachmentData,
} from './helpers'

const logger = LogHelper('client:tradeDocManagerSaga')

function* handleActiveTDMRulesFetch() {
	try {
		yield getUserProfileFromLoggedUser()
		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		const activeModules = []

		const { canReadIncoming, canReadOutgoing } = rolesMap
		const location = yield select(TradeDocDuc.selectors.location)
		const { payload = {} } = location || {}
		const { rootModule } = payload
		if (canReadIncoming && rootModule === 'incoming')
			activeModules.push('incoming')
		if (canReadOutgoing && rootModule === 'outgoing')
			activeModules.push('outgoing')

		yield put(
			TradeDocDuc.creators.setDashboardActiveTDMRules(
				activeModules,
				getActiveTypesBasedOnRole(INITIAL_TYPES, rolesMap)
			)
		)
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchUserDocuments(action) {
	try {
		const {
			docType = 'all',
			rootModules = [],
			locationState = {},
			skipGlobalLoader,
		} = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-documents'))

		yield put(
			AuthDuc.creators.fetchAllProducts(
				rootModules === 'incoming' ? 'incoming-draft' : ''
			)
		)

		let requestUrl

		yield put(AuthDuc.creators.fetchActor())
		yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))
		yield put(AuthDuc.creators.fetchGlobalOrgs())
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		const hasDraft = false
		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar
		const backendTargetQuery = {} // this would go to backend api call

		/* This depends on profile and by this time we should have user roles */
		const orgID = yield getOrgIDFromLoggedUser()

		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		const targetTypes = getActiveTypesBasedOnRole(
			docType && docType !== 'all' ? [docType] : INITIAL_TYPES,
			rolesMap
		)

		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})
		yield put(TradeDocDuc.creators.setUploadDOAcceptedList())

		// atleast one type should be allowed for the user or if its draft fetch, let the user
		if (!targetTypes.length && docType !== 'draft') {
			yield put(MainRouteDuc.creators.switch401())

			return
		}

		const { canReadIncoming, canReadOutgoing } = rolesMap

		const hasIncoming = canReadIncoming && rootModules.includes('incoming')
		const hasOutgoing = canReadOutgoing && rootModules.includes('outgoing')
		let allOrgIDs = []

		// add the type of doc when requested
		if (hasIncoming) {
			yield put(TradeDocDuc.creators.tdmLoadingStatus(true))

			// set respective loading statuses
			yield put(
				TradeDocDuc.creators.setDashboardLoading(
					'incoming',
					targetTypes
				)
			)

			const __query = {
				...backendTargetQuery,
				receivingPartyID: selectedCPID || orgID,
			}

			const _incomingQuery = existingQueryFromUrl.incoming || []
			const _sortKeys = Array.isArray(_incomingQuery)
				? _incomingQuery
				: [_incomingQuery]
			const sortQuery =
				_sortKeys && transformSortStringsToBEQueries(_sortKeys)
			// based on the selection create the blocks to call.
			const callsPipeLine = targetTypes.map(_type => {
				const _sortQuery = getIn(sortQuery, [_type]) || ''

				if (_type === 'contracts' && currentPlatform() !== 'rice') {
					requestUrl = `${getMarketplaceEndPoint()}trade/organization/-/contracts?meta=neq(creator-%3E${orgID})`
				} else if (_type === 'excel-uploads') {
					requestUrl = `${getCoreEndPoint()}entities/drafts/list?receivingPartyID=${orgID}`
				} else if (selectedCPID) {
					requestUrl = `${getCoreEndPoint()}entities/childorg/${selectedCPID}?type=${_type}&${querySerializer.stringify(
						{
							...__query,
							...{ sort: _sortQuery },
						}
					)}`
				} else if (
					!(currentPlatform() === 'rice' && _type === 'contracts')
				) {
					requestUrl = `${getCoreEndPoint()}entities?type=${_type}&${querySerializer.stringify(
						{
							...__query,
							...{ sort: _sortQuery },
						}
					)}`
				}

				return requestUrl && CallWithRefreshCheck(requestUrl)
			})

			const origResponse = yield all(callsPipeLine)
			const responses = responseDocsMapper(targetTypes, origResponse)

			// if there are active sorts, then set them in the url.
			yield put(
				TradeDocDuc.creators.fetchDashboardDocumentsSuccess(
					'incoming',
					responses
				)
			)

			// fetch org ids
			const orgIDs = extractOrgIDsFromResponses(origResponse)

			if (orgIDs.length && orgIDs[0] !== undefined)
				allOrgIDs = addLast(allOrgIDs, orgIDs)

			// update the queries as applied in backend to stay in sync
			const sortQueriesFromBE = extractSortQueries(responses, _sortKeys)
			if (sortQueriesFromBE.length) {
				frontendTargetQuery.incoming = sortQueriesFromBE
			}
		}

		if (hasOutgoing) {
			// set respective loading statuses
			yield put(TradeDocDuc.creators.tdmLoadingStatus(true))
			yield put(
				TradeDocDuc.creators.setDashboardLoading(
					'outgoing',
					targetTypes
				)
			)

			const __query = {
				...backendTargetQuery,
				initiatingPartyID: selectedCPID || orgID,
			}

			const _outgoingQuery = existingQueryFromUrl.outgoing || []
			const _sortKeys = Array.isArray(_outgoingQuery)
				? _outgoingQuery
				: [_outgoingQuery]

			const sortQuery =
				_sortKeys && transformSortStringsToBEQueries(_sortKeys)

			// based on the selection create the blocks to call.
			const callsPipeLine = targetTypes.map(_type => {
				const _sortQuery = getIn(sortQuery, [_type]) || ''

				if (_type === 'contracts' && currentPlatform() !== 'rice') {
					requestUrl = `${getMarketplaceEndPoint()}trade/organization/-/contracts?meta=creator-%3E${orgID}`
				} else if (_type === 'excel-uploads') {
					requestUrl = `${getCoreEndPoint()}entities/drafts/list?initiatingPartyID=${orgID}`
				} else if (selectedCPID) {
					requestUrl = `${getCoreEndPoint()}entities/childorg/${selectedCPID}?type=${_type}&${querySerializer.stringify(
						{
							...__query,
							...{ sort: _sortQuery },
						}
					)}`
				} else if (
					!(currentPlatform() === 'rice' && _type === 'contracts')
				) {
					requestUrl = `${getCoreEndPoint()}entities?type=${_type}&${querySerializer.stringify(
						{
							...__query,
							...{ sort: _sortQuery },
						}
					)}`
				}

				return requestUrl && CallWithRefreshCheck(requestUrl)
			})

			const origResponse = yield all(callsPipeLine)
			const responses = responseDocsMapper(targetTypes, origResponse)

			// check for drafts and if yes, make it active
			// this draft is saved document. when upload excel drafts implemented this one removed

			// const _draftSortQuery = getIn(sortQuery, ['draft']) || ''
			// const draftResponse = yield CallWithRefreshCheck(
			// 	`${getCoreEndPoint()}/entities/drafts/list?${querySerializer.stringify(
			// 		{
			// 			...__query,
			// 			...{ sort: _draftSortQuery },
			// 		}
			// 	)}`
			// )

			// const draft = getIn(draftResponse, ['data']) || []

			// hasDraft = Array.isArray(draft.list) && draft.list.length

			// fetch org ids
			const orgIDs = extractOrgIDsFromResponses(addLast(origResponse, {}))

			const targetResponses = hasDraft
				? merge(responses, { draft })
				: responses
			yield put(
				TradeDocDuc.creators.fetchDashboardDocumentsSuccess(
					'outgoing',
					targetResponses
				)
			)

			if (orgIDs.length && orgIDs[0] !== undefined)
				allOrgIDs = addLast(allOrgIDs, orgIDs)
			// update the queries as applied in backend to stay in sync
			const sortQueriesFromBE = extractSortQueries(
				targetResponses,
				_sortKeys
			)

			if (sortQueriesFromBE.length) {
				frontendTargetQuery.outgoing = sortQueriesFromBE
			}
		}
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-documents'))

		// fetch org info
		if (allOrgIDs.length)
			yield put(AuthDuc.creators.fetchOrgDetails(allOrgIDs))

		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(TradeDocDuc.creators.setActiveSorts(frontendTargetQuery))
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-documents'))
		yield put(TradeDocDuc.creators.tdmLoadingStatus(false))
		yield put(TradeDocDuc.creators.resetDashboardLoading())
	}
}

function* changeDocumentStatus(action) {
	try {
		const {
			docType,
			postChange,
			successMsg,
			errorMsg,
			docRefId = '',
			remarks = '',
			newStatus = null,
			uploadDOAcceptedList = [],
			fromUploadDoSelect = false,
		} = action.documentStatusPropsList

		if (!newStatus && !fromUploadDoSelect) {
			throw new Error(errorMsg)
		}

		const requestUrl = `${getCoreEndPoint()}entities/events`
		const currentUser = yield getUserProfileFromLoggedUser()
		const tenantID = getIn(currentUser, ['organization', 'id'])

		const eventKey = `state-change-${newStatus}`.toLowerCase()

		const getOptions = () => {
			const currentUploadDOAcceptedList = []
			if (uploadDOAcceptedList.length > 0) {
				uploadDOAcceptedList.forEach(item => {
					// need to send the accepted first and then delivered
					currentUploadDOAcceptedList.push(
						{
							event: 'state-change-accepted',
							data: {
								payload: item,
							},
						},
						{
							event: 'state-change-delivered',
							data: {
								payload: item,
							},
						}
					)
				})

				return {
					method: 'POST',
					body: JSON.stringify({
						tenantID,
						events: currentUploadDOAcceptedList,
					}),
				}
			}
		}

		const options = fromUploadDoSelect
			? getOptions()
			: {
					method: 'POST',
					body: JSON.stringify({
						tenantID,
						events: [
							{
								event: eventKey,
								data: {
									docType,
									payload: {
										id: docRefId,
									},
								},
							},
						],
					}),
			  }

		const { data } = yield CallWithRefreshCheck(requestUrl, options)
		// make the api call to change status in backend.

		if (
			getIn(data, ['events', 0, 'event']) === eventKey &&
			!fromUploadDoSelect
		) {
			// post the remark
			yield put(
				TradeDocDuc.creators.addNewRemark(
					'status-change',
					remarks,
					docRefId
				)
			)
		} else if (!fromUploadDoSelect) {
			throw new Error(getIn(data, ['errors']) || errorMsg)
		}

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: successMsg,
			})
		)
		yield put(TradeDocDuc.creators.setUploadDOAcceptedList()) // empty redux
		// give the control back to the invoker to do some post operations.
		if (postChange) postChange()
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(TradeDocDuc.creators.setDOAcceptAllLoading(false))
	}
}

function* fetchDashboardStatuses(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('dashboard-status'))
		const { rootModule, submodule, query } = action
		const checkFilter = getIn(query, ['createdAt']) || []
		const orgID = yield getOrgIDFromLoggedUser()

		let requestUrl = `${getCoreEndPoint()}entities/stats?type=${
			submodule === 'excel-uploads' ? 'drafts' : submodule
		}&${
			rootModule === 'incoming' ? 'receivingPartyID' : 'initiatingPartyID'
		}=${orgID}`
		if (checkFilter.length > 0) {
			const filterQueries = transformFilterStringsToBEQueries({
				createdAt: query.createdAt,
			})
			requestUrl += `&createdAt=${filterQueries.createdAt}`
		}
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(
			TradeDocDuc.creators.fetchDashboardStatsSuccess(rootModule, data)
		)
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-status'))
	}
}

const PAGINATION_LIMIT = 20

function* fetchDocumentListing(action) {
	try {
		const {
			rootModule,
			submodule,
			locationState = {},
			skipGlobalLoader,
		} = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-listing'))

		if (submodule === 'excel-uploads') {
			yield put(AuthDuc.creators.fetchAllProducts())
			yield put(TradeDocDuc.creators.fetchDocumentListingSuccess({}))
		}
		const { type, payload, query } = locationState
		yield put(
			TradeDocDuc.creators.fetchDashboardStats(
				rootModule,
				submodule,
				query
			)
		)
		yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))
		yield put(AuthDuc.creators.fetchGlobalOrgs())
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call
		// handle the sort conditions based on what's selected here.
		// show local loader
		yield put(TradeDocDuc.creators.fetchDocumentLoading(true))
		const orgID = yield getOrgIDFromLoggedUser()
		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const searchQuery = existingQueryFromUrl.q || ''

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}
		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]
		let _sortQuery = _sortKeys && transformSortStringsToBEQueries(_sortKeys)

		_sortQuery = getIn(_sortQuery, [submodule]) || ''
		const __query = {
			...backendTargetQuery,
			[rootModule === 'incoming'
				? 'receivingPartyID'
				: 'initiatingPartyID']: orgID,
		}
		const requestUrl =
			submodule === 'excel-uploads'
				? `${getCoreEndPoint()}entities/drafts/list?${querySerializer.stringify(
						{
							...__query,
							...(searchQuery && { search: searchQuery }),
						}
				  )}`
				: `${getCoreEndPoint()}entities?type=${submodule}&${querySerializer.stringify(
						{
							...__query,
							...{ sort: _sortQuery },
							...(searchQuery && { search: searchQuery }),
						}
				  )}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		// const origResponse = myFakeHardcode('with-filter')
		const response = getIn(origResponse, ['data']) || {}
		// server pagination query
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}
		// extract pagination queries from response
		yield put(
			TradeDocDuc.creators.setPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)

		// fetch org ids
		const orgIDs = extractOrgIDsFromResponses([origResponse])
		if (orgIDs.length) yield put(AuthDuc.creators.fetchOrgDetails(orgIDs))

		// update the queries as applied in backend to stay in sync
		const sortQueriesFromBE = extractSortQueries(
			{ [submodule]: response },
			_sortKeys
		)

		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		yield put(TradeDocDuc.creators.setActiveListingFilters(stateTree))

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)

		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}

		yield put(TradeDocDuc.creators.fetchDocumentListingSuccess(response))

		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				TradeDocDuc.creators.setActiveListingSorts(frontendTargetQuery)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(TradeDocDuc.creators.fetchDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-listing'))
	}
}

function* initiateDocumentStep() {
	try {
		const location = yield select(TradeDocDuc.selectors.location)
		const { payload = {} } = location || {}

		const { action, rootModule } = payload

		// fetch the current org details
		const currentOrgID = yield getOrgIDFromLoggedUser()
		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		// check for the user roles and if permitted to move further
		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		const allowedTypes = getActiveTypesBasedOnRole([rootModule], rolesMap)

		if (!allowedTypes.length) {
			// user is not authorized to see this module.
			yield put(TradeDocDuc.creators.documentLoading(false, false, true))

			return
		}

		// Check if there is already associated partner, if yes, fetch the details and move to details view
		const activeDocument = yield select(
			TradeDocDuc.selectors.getDocumentActiveRecord
		)

		// fetch the relevant entity references
		const {
			initiatingPartyID,
			initiatingUserID,
			receivingUserID,
			receivingPartyID,
		} = activeDocument

		const activePartner = yield select(
			TradeDocDuc.selectors.getDocumentActivePartner
		)

		if (receivingPartyID) {
			const docStatus = getIn(activeDocument, ['docStatus'])

			// check if draft during one of the edit flow
			if (
				docStatus &&
				payload.documentStatus !== 'clone' &&
				payload.documentStatus !== 'edit' &&
				docStatus !== 'draft' &&
				action
			) {
				// The user somehow landed on an editing page when the document is
				// not a draft anymmore. So show error during thi
				yield put(
					TradeDocDuc.creators.documentLoading(false, false, true)
				)

				return
			}

			// set active partner
			if (!activePartner.id) {
				const partner =
					currentOrgID === receivingPartyID
						? initiatingPartyID
						: receivingPartyID
				yield put(
					TradeDocDuc.creators.setActivePartner({
						id: partner,
					})
				)
			}
		}

		if (action === 'details' || action === 'attachDetails') {
			yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))

			// fetch the partner address details
			if (activePartner.id && rootModule !== 'purchase-order')
				yield put(
					AuthDuc.creators.fetchOrgDetails([activePartner.id], false)
				)

			// fetch all the products
			yield put(AuthDuc.creators.fetchAllProducts(rootModule))
			yield put(AuthDuc.creators.fetchCertificates())
			yield put(AuthDuc.creators.fetchAllStorageUnits())
			yield put(AuthDuc.creators.fetchGlobalOrgs())
		}

		// if we do not have the org details, fetch it.
		if (initiatingPartyID && receivingPartyID) {
			const organizations = yield select(
				AuthDuc.selectors.getAvailableOrgs
			)
			if (
				!organizations[initiatingPartyID] ||
				!organizations[receivingPartyID]
			) {
				yield fetchDocumentPartipants({
					initiatingPartyID,
					initiatingUserID,
					receivingUserID,
					receivingPartyID,
				})
			}
		}
		// hide the loader
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		TradeDocDuc.creators.documentLoading(false, false, true)
	}
}

const transformDataForDocTrace = origResponse => {
	const docTraceData = {}
	docTraceData.id = origResponse.entity.id
	docTraceData.entityName =
		origResponse.entity.type &&
		origResponse.entity.type.replace('-', ' ').toUpperCase()
	docTraceData.entityType = origResponse.entity.type
	docTraceData.currentEntityID = origResponse.entity.id
	docTraceData.version = origResponse.entity.version || '---'
	docTraceData.entityID = origResponse.entity.number
	docTraceData.sentDate = origResponse.entity.createdAt
	docTraceData.actionDate =
		origResponse.entity.status && origResponse.entity.status.updatedAt
	docTraceData.status =
		origResponse.entity.status && origResponse.entity.status.state
	docTraceData.messages = origResponse.entity.remarks || 0

	if (origResponse.trace) {
		docTraceData.children = origResponse.trace
		origResponse.trace.forEach((data, index) => {
			docTraceData.children[index] = transformDataForDocTrace(data)
		})
	}

	return docTraceData
}

const docTraceData = origResponse => {
	origResponse.forEach((ob, index) => {
		// eslint-disable-next-line no-param-reassign
		origResponse[index] = transformDataForDocTrace(ob)
	})

	return { origResponse }
}

function* fetchDocumentAttachments(action) {
	try {
		const { documentReference } = getIn(action, ['action']) || {}

		const attachmentRequestUrl = `${getCoreEndPoint()}entities/${documentReference}/attachments`
		const { data: _data } = yield CallWithRefreshCheck(attachmentRequestUrl)

		const attachments = []

		_data.forEach(attachment => {
			if (attachment.meta.category === 'documents') {
				attachments.push(otherAttachmentData(attachment))
			} else if (attachment.meta.category === 'bill-of-lading') {
				attachments.push(billOfLadingAttachmentData(attachment))
			}
		})

		yield put(TradeDocDuc.creators.setAttachmentDoc(attachments))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('attachment-status'))
	}
}

const getGroupTraceDataSet = traceDataset => {
	if (
		traceDataset.orgTraceGroupMap &&
		Object.keys(traceDataset.orgTraceGroupMap).length &&
		traceDataset.orgTraceGroupMap[
			Object.keys(traceDataset.orgTraceGroupMap)[0]
		].sourceTraces.length
	) {
		return traceDataset.orgTraceGroupMap[
			Object.keys(traceDataset.orgTraceGroupMap)[0]
		].sourceTraces[0]
	}

	return {}
}

const getAllOrgTraceGroupMapLCV = sourceTraces => {
	let lcv = 0
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(item => {
			lcv += item.lcv || 0
		})
	}

	return lcv
}

const getAllOrgTraceGroupMap = sourceTraces => {
	let traceGroups = {}
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.orgTraceGroupMap &&
				Object.keys(trace.orgTraceGroupMap).length > 0
			) {
				Object.keys(trace.orgTraceGroupMap).forEach(traceKey => {
					if (traceGroups[traceKey]) {
						const tempData = { ...traceGroups[traceKey] }
						tempData.sourceTraces = [
							...traceGroups[traceKey].sourceTraces,
							...trace.orgTraceGroupMap[traceKey].sourceTraces,
						]
						traceGroups[traceKey] = tempData
					} else {
						traceGroups = {
							...traceGroups,
							...trace.orgTraceGroupMap,
						}
					}
				})
			}
		})
	}

	return traceGroups
}

const getAllInitiatorTraceIDS = sourceTraces => {
	let initiatorTraceIDS = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.entity &&
				trace.entity.initiatorTraceIDs &&
				trace.entity.initiatorTraceIDs.length > 0
			) {
				let totalQty = 0
				let availableQty = 0
				let quantityUtilized = 0
				trace.entity.initiatorTraceIDs.forEach(item => {
					availableQty += item.availableQty
					quantityUtilized += item.quantityUtilized
					totalQty += item.totalQty
				})
				const traceIDs = [
					{
						...trace.entity.initiatorTraceIDs[0],
						totalQty,
						availableQty,
						quantityUtilized,
						products: trace.entity.products,
						supplyChainModel:
							trace.entity.meta.supplyChainModel || {},
					},
				]
				initiatorTraceIDS = [...initiatorTraceIDS, ...traceIDs]
			}
		})
	}

	return initiatorTraceIDS
}

const getAllReceiverTraceIDs = sourceTraces => {
	let receiverTraceIDs = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.entity &&
				trace.entity.receiverTraceIDs &&
				trace.entity.receiverTraceIDs.length > 0
			) {
				let totalQty = 0
				let availableQty = 0
				let quantityUtilized = 0
				trace.entity.receiverTraceIDs.forEach(item => {
					availableQty += item.availableQty
					quantityUtilized += item.quantityUtilized
					totalQty += item.totalQty
				})
				const traceIDs = [
					{
						...trace.entity.receiverTraceIDs[0],
						totalQty,
						availableQty,
						quantityUtilized,
						products: trace.entity.products,
						supplyChainModel:
							trace.entity.meta.supplyChainModel || {},
					},
				]
				receiverTraceIDs = [...receiverTraceIDs, ...traceIDs]
			}
		})
	}

	return receiverTraceIDs
}

const getAllSupplyChainModel = sourceTraces => {
	const supplyChainType = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (
				trace.entity &&
				trace.entity.meta.supplyChainModel &&
				trace.entity.meta.supplyChainModel.id
			) {
				supplyChainType.push(trace.entity.meta.supplyChainModel.id)
			}
		})
	}

	return supplyChainType
}

const getAllProductEntity = sourceTraces => {
	const enitity = []
	if (sourceTraces.length > 0) {
		sourceTraces.forEach(trace => {
			if (trace.entity) {
				enitity.push(trace.entity)
			}
		})
	}

	return enitity
}

const getLCV = traceDataset => {
	return traceDataset?.lcv || 0
}

const getAllChildLCV = orgTraceGroupMap => {
	let lcv = 0
	if (Object.keys(orgTraceGroupMap).length) {
		Object.keys(orgTraceGroupMap).forEach(traceGroup => {
			if (orgTraceGroupMap[traceGroup].sourceTraces.length > 0) {
				orgTraceGroupMap[traceGroup].sourceTraces.forEach(item => {
					if (
						item.lcv &&
						item.orgTraceGroupMap &&
						Object.keys(item.orgTraceGroupMap).length
					) {
						lcv += item?.lcv || 0
					}
					if (item.orgTraceGroupMap) {
						lcv += getAllChildLCV(item.orgTraceGroupMap, lcv)
					}
				})
			}
		})
	}

	return lcv
}

const transformDataForTree = (
	dataResponse,
	mapResponse,
	badNodePaths,
	isTraceGroup = false
) => {
	let traceDataset = {}
	if (isTraceGroup) {
		traceDataset = dataResponse?.traceDataset
			? getGroupTraceDataSet(dataResponse?.traceDataset)
			: dataResponse
	} else {
		traceDataset = dataResponse?.traceDataset
			? dataResponse?.traceDataset
			: dataResponse
	}

	const currentOrgID = dataResponse?.traceGroup
		? dataResponse?.traceGroup
		: Object.keys(dataResponse?.traceDataset?.orgTraceGroupMap)[0]

	const currentOrg =
		currentOrgID === traceDataset?.destinationOrg?.id
			? traceDataset?.destinationOrg
			: traceDataset?.originatingOrg
	let traceIDs = []
	if (
		traceDataset.entity &&
		traceDataset.entity.receiverTraceIDs &&
		traceDataset.entity.receiverTraceIDs.length > 0
	) {
		traceIDs = traceDataset.entity.receiverTraceIDs
	} else if (
		traceDataset.entity &&
		traceDataset.entity.initiatorTraceIDs &&
		traceDataset.entity.initiatorTraceIDs.length > 0
	) {
		traceIDs = traceDataset.entity.initiatorTraceIDs
	} else {
		traceIDs = traceDataset?.productionInput?.quantities || []
	}
	const errorMsg =
		traceDataset?.productionInput?.extractionRatio?.messages[0] || ''
	const { id: _id } = currentOrg || {}
	// eslint-disable-next-line no-param-reassign
	dataResponse.path = (dataResponse.path || '') + _id
	const primaryAddress = currentOrg?.primaryAddress || {}
	const blockChainStatus = 'verified'
	const treeData = {
		childIndex: traceDataset.childIndex || 0,
	}
	treeData.path = (dataResponse.path || '') + _id
	treeData.id = _id
	treeData.title =
		currentOrg?.name + (traceDataset.productionInput ? ' (Production)' : '')
	treeData.status = 'good'
	treeData.orgType = getIn(currentOrg, ['categories', 0, 'name']) || ''
	treeData.orgTypeID = getIn(currentOrg, ['categories', 0, 'id']) || ''
	treeData.blockChainStatus = blockChainStatus
	treeData.errorMsg = errorMsg
	treeData.virtualTraceData = !(
		getIn(currentOrg, ['status', 'state']) === 'verified' ||
		getIn(currentOrg, ['status', 'state']) === 'pending'
	)
	treeData.unKnownPercentage = traceDataset?.unknownPercentage
		? traceDataset?.unknownPercentage
		: null
	const { certificateTypes } = dataResponse
	const certificateTypesList =
		certificateTypes && certificateTypes.length > 0
			? certificateTypes.map(item => {
					return {
						label: item === 'UNCERTIFIED' ? 'NONE' : item,
						key: item,
					}
			  })
			: []
	treeData.certificateTypes = certificateTypesList
	const { supplyChainTypes } = dataResponse
	const supplyChainTypesList = []
	if (supplyChainTypes?.length > 0) {
		supplyChainTypes.forEach(supplyChainType => {
			supplyChainTypesList.push({
				name: supplyChainType.value,
				label: supplyChainType.label,
			})
		})
	}
	treeData.supplyChainTypes = supplyChainTypesList
	const address = `
		${getIn(primaryAddress, ['line1']) || ''}
		${getIn(primaryAddress, ['line2']) || ''}
		${getIn(primaryAddress, ['postalCode']) || ''} -
		${getIn(primaryAddress, ['city']) || ''}
		${getIn(primaryAddress, ['state']) || ''}
		${getIn(primaryAddress, ['country']) || ''}
	`
	const certType = []
	const allCerts = getIn(traceDataset, ['orgCertificates']) || []
	if (allCerts.length > 0) {
		allCerts.forEach(certificateData => {
			const ctype =
				getIn(certificateData, [
					'meta',
					'certificate',
					'issuingBody',
				]) || null
			const certURL =
				getIn(certificateData, ['files', 0, 'meta', 'fullURL']) || null
			const currentState =
				getIn(certificateData, ['status', 'state']) || null
			const expiryEndDate =
				getIn(certificateData, ['expiryEndDate']) || ''
			let currentStateList = []
			if (currentState && currentState.length > 0) {
				currentStateList = currentState.split('-')
				currentStateList.forEach((word, index) => {
					currentStateList[index] =
						word[0].toUpperCase() + word.substr(1)
				})
			}
			const state =
				currentStateList.length > 0
					? currentStateList.join(' and ')
					: ''
			if (ctype) {
				certType.push({ ctype, certURL, state, expiryEndDate })
			}
		})
	}

	let supplyBase = false
	if (traceDataset.supplyBase) {
		supplyBase = true
		if (traceDataset.supplyBase.length > 0) {
			traceDataset.supplyBase.forEach(item => {
				certType.push({
					ctype: item.certifiedBy,
					state: item.documentState,
					certURL: item.certificateLink,
					expiryEndDate: item.expiryEndDate,
					supplyChainModel: item.supplyChainModel,
				})
			})
		}
	}
	if (certType.length === 0) {
		certType.push({
			ctype: 'UNCERTIFIED',
			state: 'Active',
		})
	}

	treeData.supplyBase = supplyBase
	treeData.certType = certType
	let supplyChainType = []
	if (traceDataset.entity && traceDataset.entity.meta.supplyChainType) {
		// eslint-disable-next-line prefer-destructuring
		supplyChainType = traceDataset.entity.meta.supplyChainType
	} else if (
		traceDataset.entity &&
		traceDataset.entity.meta.supplyChainModel
	) {
		supplyChainType = [traceDataset.entity.meta.supplyChainModel.id]
	}
	treeData.supplyChainType = supplyChainType
	treeData.deforestationData =
		getIn(currentOrg, ['meta', 'deforestationData']) || []
	treeData.shape =
		getIn(primaryAddress, ['location', 'geoData', 'shape']) || {}
	treeData.geoType =
		getIn(primaryAddress, ['location', 'geoData', 'type']) || ''
	treeData.transforming = []
	treeData.transformed = []

	treeData.transaction = []
	if (traceIDs && traceIDs.length > 0) {
		const uniqueTraceIDs = [
			...new Map(traceIDs.map(item => [item.traceID, item])).values(),
		]
		const totalQuantity = uniqueTraceIDs.reduce((sum, current) => {
			return (
				sum +
				(current.quantityUtilized ||
					current.totalQty ||
					current.quantity ||
					0)
			)
		}, 0)

		const totalLCV =
			getLCV(traceDataset) + getAllChildLCV(traceDataset.orgTraceGroupMap)

		const getExchangesListed = exchangeListed => {
			return exchangeListed ? 'yes' : 'no'
		}

		treeData.currentOrgDetail = {
			orgID: getIn(currentOrg, ['id']) || '',
			exchangeListed:
				typeof currentOrg?.meta?.exchangeListed !== 'undefined'
					? getExchangesListed(currentOrg?.meta?.exchangeListed)
					: '',
			exchangeListedCountry:
				currentOrg?.meta?.exchangeListedCountry || [],
			carbonNumberData: currentOrg?.meta?.carbonNumberData || false,
			deforestationAlert: currentOrg?.deforestationAlert || false,
		}

		const transaction = traceIDs.map(item => {
			const quantity =
				item.quantityUtilized || item.totalQty || item.quantity || 0

			return {
				createdAt: traceDataset.entity.createdAt,
				product: traceDataset.product
					? traceDataset.product.name
					: ' --- ',
				quantity: getQuantityWithUnit(quantity, getIn(item, ['uom'])),
				entityID: traceDataset.entity?.id || '---',
				entityNumber: traceDataset.entity?.number || '---',
				certType,
				totalLCV: `${Lodash.round(totalLCV, 2)} KgCO2e/ton`,
				lcv: `${
					item.products
						? getIn(item, ['products', 0, 'lcv'])
						: getIn(traceDataset, ['entity', 'products', 0, 'lcv'])
				} KgCO2e/ton`,
				location: {
					long: parseFloat(
						getIn(primaryAddress, ['location', 'long']) || 0,
						5
					),
					lat: parseFloat(
						getIn(primaryAddress, ['location', 'lat']) || 0,
						5
					),
					geoData:
						getIn(primaryAddress, ['location', 'geoData']) || {},
				},
				locationOfTrace: address,
				totalQuantity: getQuantityWithUnit(
					totalQuantity,
					getIn(item, ['uom'])
				),
				blockChainFailed: false,
				traceID: item.traceID,
				supplyChainModel: item.supplyChainModel
					? item.supplyChainModel
					: traceDataset.entity.meta.supplyChainModel || {},
			}
		})
		treeData.transaction = transaction
		if (treeData.traceGroupStatus !== 'transformed') {
			treeData.transforming = transaction
		} else {
			treeData.transformed = transaction
		}
	}
	mapResponse.push({
		name: currentOrg?.name,
		orgTypeID: getIn(currentOrg, ['categories', 0, 'id']) || '',
		orgType: getIn(currentOrg, ['categories', 0, 'name']) || '',
		orgID: getIn(currentOrg, ['id']) || '',
		coordinates: [
			parseFloat(
				getIn(primaryAddress, [
					'location',
					'geoData',
					'shape',
					'center',
					0,
				]) ||
					getIn(primaryAddress, ['location', 'long']) ||
					0,
				5
			),
			parseFloat(
				getIn(primaryAddress, [
					'location',
					'geoData',
					'shape',
					'center',
					1,
				]) ||
					getIn(primaryAddress, ['location', 'lat']) ||
					0,
				5
			),
		],
		status: '',
		universalMillID: getIn(currentOrg, ['meta', 'universalMillID']) || '',
		path: dataResponse.path,
		certType,
		certificateTypes: certificateTypesList,
		supplyChainTypes: supplyChainTypesList,
		location: {
			long: parseFloat(
				getIn(primaryAddress, ['location', 'long']) || 0,
				5
			),
			lat: parseFloat(getIn(primaryAddress, ['location', 'lat']) || 0, 5),
		},
		locationOfTrace: address,
		totalQuantity: traceIDs
			? traceIDs.reduce((sum, current) => {
					return sum + (current.totalQty || 0)
			  }, 0)
			: 0,
		deforestationData:
			getIn(currentOrg, ['meta', 'deforestationData']) || [],
	})

	if (
		traceDataset.orgTraceGroupMap &&
		Object.keys(traceDataset.orgTraceGroupMap).length
	) {
		treeData.children = Object.keys(traceDataset.orgTraceGroupMap).map(
			traceGroup => {
				return traceDataset.orgTraceGroupMap[traceGroup]
					?.sourceTraces[0]
			}
		)
		Object.keys(traceDataset.orgTraceGroupMap).forEach(
			(traceGroup, index) => {
				const sourceTraces =
					traceDataset.orgTraceGroupMap[traceGroup]?.sourceTraces
				const data = { ...sourceTraces[0] } || {}
				// data.lcv = getAllOrgTraceGroupMapLCV(sourceTraces)
				data.orgTraceGroupMap = getAllOrgTraceGroupMap(
					sourceTraces || []
				)
				data.productEntity = getAllProductEntity(sourceTraces || [])
				if (data.entity) {
					const currData = { ...data.entity }
					const currMeta = { ...currData.meta }
					currData.initiatorTraceIDs = getAllInitiatorTraceIDS(
						sourceTraces || []
					)
					currMeta.supplyChainType = getAllSupplyChainModel(
						sourceTraces || []
					)
					currData.receiverTraceIDs = getAllReceiverTraceIDs(
						sourceTraces || []
					)
					currData.meta = currMeta
					data.entity = currData
				}
				const d = Object.assign({ childIndex: index }, data)
				d.path = `${dataResponse.path}|`
				d.certificateTypes = certificateTypes
				d.supplyChainTypes = supplyChainTypes
				d.errorMsg = errorMsg
				d.traceGroup = traceGroup
				const traceData = transformDataForTree(
					d,
					mapResponse,
					badNodePaths
				)
				treeData.children[index] = traceData.treeData
			}
		)
	}

	return { treeData, mapResponse, badNodePaths }
}

const traceTreeData = (dataResponse, isTraceGroup = false) => {
	const mapResponse = []
	const badNodePaths = {}
	dataResponse.forEach((ob, index) => {
		// eslint-disable-next-line no-param-reassign
		dataResponse[index] = transformDataForTree(
			ob,
			mapResponse,
			badNodePaths,
			isTraceGroup
		)
	})

	return { dataResponse, badNodePaths }
}

function* initiateDocumentView(action) {
	try {
		yield put(AuthDuc.creators.fetchAllProducts())

		if (action && !action.fromTraceReport) {
			yield put(AppDuc.creators.showGlobalLoader('document-view'))
		}

		const { documentReference, rootModule, fromTraceReport } = action || {}
		// fetch the document with given reference
		yield put(
			TradeDocDuc.creators.fetchActiveDocument(
				documentReference,
				rootModule
			)
		)
		yield put(
			TradeDocDuc.creators.setActiveTab(
				fromTraceReport ? 'traceReport' : 'original'
			)
		)

		yield put(AuthDuc.creators.fetchActor())
		const orgID = yield getOrgIDFromLoggedUser()

		const [orgDetails] = yield fetchOrgsDetails({
			orgIDs: [orgID],
			returnResponse: true,
		})
		yield fetchDOUploadStatus()

		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		const requestUrl = `${getCoreEndPoint()}entities/${documentReference}/trace`
		const { data } = yield CallWithRefreshCheck(requestUrl)

		let entityRequestUrl = `${getCoreEndPoint()}entities/${documentReference}`
		if (selectedCPID) {
			entityRequestUrl = `${getCoreEndPoint()}entities/${documentReference}/childorg/${selectedCPID}`
		}
		const { data: entitiesResponse } = yield CallWithRefreshCheck(
			entityRequestUrl
		)

		const attachmentRequestUrl = `${getCoreEndPoint()}entities/${documentReference}/attachments`
		const { data: _data } = yield CallWithRefreshCheck(attachmentRequestUrl)

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/-/partners?limit=100`
		const { data: orgData = {} } = yield CallWithRefreshCheck(orgDetailsUrl)

		const productsUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=100`
		const { data: productData = {} } = yield CallWithRefreshCheck(
			productsUrl
		)
		const actor = (yield select(AuthDuc.selectors.getActor))[0]

		const traceID =
			getIn(entitiesResponse, ['receiverTraceIDs', '0', 'traceID']) || ''
		const traceGroupID =
			getIn(entitiesResponse, ['meta', 'receiverTraceGroupID']) || ''
		const actorWithoutTraceReport =
			actor.includes('palmoil_ffbdealer') ||
			actor.includes('palmoil_ffbsubdealer') ||
			actor.includes('palmoil_plantation') ||
			actor.includes('rice-dealer')
		if (
			!actorWithoutTraceReport &&
			getIn(entitiesResponse, ['meta', 'traceGenerated']) &&
			currentPlatform() === 'palmoil' &&
			// !getIn(entitiesResponse, [
			// 	'meta',
			// 	'globalReceiver',
			// 	'contact',
			// 	'email',
			// ]) &&
			((traceGroupID && traceGroupID !== '') ||
				(traceID && traceID !== '') ||
				(getIn(entitiesResponse, ['status', 'state']) === 'submitted' &&
					getIn(entitiesResponse, ['traceGroupID'])))
		) {
			let traceRequestUrl = ''
			if (
				getIn(entitiesResponse, ['status', 'state']) === 'submitted' &&
				getIn(entitiesResponse, ['traceGroupID'])
			) {
				const queryParam = `traceGroupID=${getIn(entitiesResponse, [
					'traceGroupID',
				])}&entityID=${getIn(entitiesResponse, [
					'id',
				])}&orgID=${getIn(entitiesResponse, ['receivingPartyID'])}`

				traceRequestUrl = `${getTraceabilityEndPoint()}clients/organizations/transaction/state/backward?${queryParam}`
			} else {
				const queryParam =
					traceGroupID && traceGroupID !== ''
						? `traceGroupID=${traceGroupID}`
						: `traceID=${traceID}`

				traceRequestUrl = `${getTraceabilityEndPoint()}clients/organizations/${getIn(
					entitiesResponse,
					['receivingPartyID']
				)}/transaction/backward?${queryParam}`
			}

			const traceData = yield CallWithRefreshCheck(traceRequestUrl)
			let tracegroupData = {}
			if (
				(traceGroupID && traceGroupID !== '') ||
				(getIn(entitiesResponse, ['status', 'state']) === 'submitted' &&
					getIn(entitiesResponse, ['traceGroupID'])) // for submitted and DO with TraceGroupID
			) {
				if (
					getIn(traceData, [
						'data',
						'traceDataset',
						'orgTraceGroupMap',
					]) &&
					Object.keys(
						getIn(traceData, [
							'data',
							'traceDataset',
							'orgTraceGroupMap',
						])
					).length > 0
				) {
					Object.keys(
						getIn(traceData, [
							'data',
							'traceDataset',
							'orgTraceGroupMap',
						])
					).forEach(traceKey => {
						if (
							getIn(traceData, [
								'data',
								'traceDataset',
								'orgTraceGroupMap',
							])[traceKey] &&
							getIn(traceData, [
								'data',
								'traceDataset',
								'orgTraceGroupMap',
							])[traceKey].sourceTraces &&
							getIn(traceData, [
								'data',
								'traceDataset',
								'orgTraceGroupMap',
							])[traceKey].sourceTraces.length > 0
						) {
							// eslint-disable-next-line prefer-destructuring
							tracegroupData = { ...getIn(traceData, ['data']) }
							let keyName = ''
							let keyID = ''

							if (
								traceKey ===
								getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey]?.sourceTraces[0]?.destinationOrg
									?.id
							) {
								keyName = getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey]?.sourceTraces[0]?.destinationOrg
									?.name
								keyID = getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey]?.sourceTraces[0]?.destinationOrg
									?.id
							} else {
								keyName = getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey]?.sourceTraces[0]?.originatingOrg
									?.name
								keyID = getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey]?.sourceTraces[0]?.originatingOrg
									?.id
							}
							// eslint-disable-next-line prefer-destructuring
							tracegroupData.traceDataset = getIn(traceData, [
								'data',
								'traceDataset',
								'orgTraceGroupMap',
							])[traceKey].sourceTraces[0]
							tracegroupData.blockChainReference =
								getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey].sourceTraces[0] &&
								getIn(traceData, [
									'data',
									'traceDataset',
									'orgTraceGroupMap',
								])[traceKey].sourceTraces[0].blockChainReference
									? getIn(traceData, [
											'data',
											'traceDataset',
											'orgTraceGroupMap',
									  ])[traceKey].sourceTraces[0]
											.blockChainReference
									: {}
							tracegroupData.updatedAt = getIn(traceData, [
								'data',
								'traceDataset',
								'orgTraceGroupMap',
							])[traceKey].sourceTraces[0].traceIDCreatedAt
								? getIn(traceData, [
										'data',
										'traceDataset',
										'orgTraceGroupMap',
								  ])[traceKey].sourceTraces[0].traceIDCreatedAt
								: ''
							tracegroupData.traceUpdatedAt =
								getIn(traceData, ['data', 'updatedAt']) || ''
							tracegroupData.eudrCompliance =
								getIn(traceData, [
									'data',
									'transactionTraceMeta',
									'eudrCompliance',
								]) || ''
							tracegroupData.unknownFlag =
								getIn(traceData, ['data', 'unknownFlag']) || ''
							if (
								getIn(entitiesResponse, ['status', 'state']) ===
									'submitted' &&
								getIn(entitiesResponse, ['traceGroupID'])
							) {
								tracegroupData.traceDataset.keyName = getIn(
									traceData,
									['data', 'traceID']
								)
									? getIn(traceData, ['data', 'traceID'])
									: ''
							}
							tracegroupData.traceDataset.keyName = keyName
							tracegroupData.traceDataset.keyID = keyID
						}
					})
				}

				const finalData = { ...tracegroupData }
				if (
					getIn(finalData, ['blockChainReference', 'topicID']) &&
					getIn(finalData, [
						'blockChainReference',
						'topicSequenceNumber',
					])
				) {
					// remove this code as part of implementaion change after the final decision on implementation
					const mirrorUrl = `${getMirrorNodeBaseUrl()}api/v1/topics/${getIn(
						finalData,
						['blockChainReference', 'topicID']
					)}/messages/${getIn(finalData, [
						'blockChainReference',
						'topicSequenceNumber',
					])}`
					const options = {
						method: 'GET',
						credentials: 'same-origin',
					}
					// const mirrorData = yield call(request, mirrorUrl, options)
					const mirrorData = {}

					if (
						mirrorData &&
						getIn(mirrorData, [
							'chunk_info',
							'initial_transaction_id',
							'transaction_valid_start',
						]) &&
						mirrorData.consensus_timestamp &&
						mirrorData.payer_account_id
					) {
						const validSplit = getIn(mirrorData, [
							'chunk_info',
							'initial_transaction_id',
							'transaction_valid_start',
						]).split('.')
						const validStartId = validSplit.join('-')

						const explorerUrl = `${getExplorerBaseurl()}transaction/${
							mirrorData.consensus_timestamp
						}?tid=${mirrorData.payer_account_id}-${validStartId}`
						const blockChainReferenceData = {
							...finalData.blockChainReference,
						}

						blockChainReferenceData.explorerUrl = explorerUrl

						finalData.blockChainReference = blockChainReferenceData
						yield put(
							TradeDocDuc.creators.setBackwardTrace(finalData)
						)
					}
				} else {
					yield put(
						TradeDocDuc.creators.setBackwardTrace(tracegroupData)
					)
				}
			} else {
				const finalData = { ...traceData.data }
				if (
					getIn(traceData, [
						'data',
						'blockChainReference',
						'topicID',
					]) &&
					getIn(traceData, [
						'data',
						'blockChainReference',
						'topicSequenceNumber',
					])
				) {
					// remove this code as part of implementaion change after the final decision on implementation
					const mirrorUrl = `${getMirrorNodeBaseUrl()}api/v1/topics/${getIn(
						traceData,
						['data', 'blockChainReference', 'topicID']
					)}/messages/${getIn(traceData, [
						'data',
						'blockChainReference',
						'topicSequenceNumber',
					])}`
					const options = {
						method: 'GET',
						credentials: 'same-origin',
					}
					// const mirrorData = yield call(request, mirrorUrl, options)
					const mirrorData = {}

					if (
						mirrorData &&
						getIn(mirrorData, [
							'chunk_info',
							'initial_transaction_id',
							'transaction_valid_start',
						]) &&
						mirrorData.consensus_timestamp &&
						mirrorData.payer_account_id
					) {
						const validSplit = getIn(mirrorData, [
							'chunk_info',
							'initial_transaction_id',
							'transaction_valid_start',
						]).split('.')
						const validStartId = validSplit.join('-')

						const explorerUrl = `${getExplorerBaseurl()}transaction/${
							mirrorData.consensus_timestamp
						}?tid=${mirrorData.payer_account_id}-${validStartId}`
						const blockChainReferenceData = {
							...traceData.data.blockChainReference,
						}

						blockChainReferenceData.explorerUrl = explorerUrl

						finalData.blockChainReference = blockChainReferenceData
						yield put(
							TradeDocDuc.creators.setBackwardTrace(finalData)
						)
					}
				} else {
					yield put(TradeDocDuc.creators.setBackwardTrace(finalData))
				}
			}

			const { dataResponse, badNodePaths } = traceTreeData(
				[traceData.data],
				traceGroupID && traceGroupID !== ''
			)

			const mapResponse = getIn(dataResponse, [0, 'mapResponse']) || []
			const treeData = getIn(dataResponse, [0, 'treeData']) || {}
			yield put(
				TradeDocDuc.creators.fetchProductTreeTraceSuccess([treeData])
			)
			yield put(
				TradeDocDuc.creators.fetchProductMapTraceSuccess(mapResponse)
			)
		}

		const organizations = getIn(orgData, ['list'])
		const products = getIn(productData, ['list'])

		const weighBridgeTickets = []
		const ffbQualityReports = []
		const cpoQualityReports = []
		const otherAttachments = []
		const billOfLadingAttachments = []

		_data.forEach(attachment => {
			if (attachment.meta.category === 'weighbridge') {
				weighBridgeTickets.push(
					weighbridgeData(
						attachment,
						orgDetails,
						organizations,
						products,
						entitiesResponse
					)
				)
			}
			if (attachment.meta.category === 'quality-palmoil-ffb') {
				ffbQualityReports.push(
					ffbQualityData(
						attachment,
						orgDetails,
						organizations,
						entitiesResponse
					)
				)
			}
			if (attachment.meta.category === 'quality-palmoil-cpo') {
				cpoQualityReports.push(
					cpoQualityData(
						attachment,
						orgDetails,
						organizations,
						entitiesResponse
					)
				)
			}
			if (attachment.meta.category === 'documents') {
				otherAttachments.push(
					otherAttachmentData(
						attachment,
						orgDetails,
						organizations,
						entitiesResponse
					)
				)
			}
			if (attachment.meta.category === 'bill-of-lading') {
				billOfLadingAttachments.push(
					billOfLadingAttachmentData(
						attachment,
						orgDetails,
						organizations,
						entitiesResponse
					)
				)
			}
		})

		const allAttachments = [
			...weighBridgeTickets,
			...ffbQualityReports,
			...cpoQualityReports,
			...otherAttachments,
			...billOfLadingAttachments,
		]

		const dataToTransform = getIn(data, ['trace']) || []

		const response = docTraceData(dataToTransform)

		yield put(TradeDocDuc.creators.setDocumentTrace(response.origResponse))
		yield put(TradeDocDuc.creators.setAttachmentDoc(allAttachments))

		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	} finally {
		if (action && !action.fromTraceReport) {
			yield put(AppDuc.creators.hideGlobalLoader('document-view'))
		}
	}
}

function* getHederaMessages(action) {
	const { entityIDs } = action
	try {
		const requestUrl = `${getCoreEndPoint()}trace/hedera/transactions?refid=${entityIDs}`
		const { data } = yield call(request, requestUrl)
		yield put(TradeDocDuc.creators.setHederaMessages({ data }))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* getDeforestationEvents(action) {
	const { orgID } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-deforestation-alert'))

		const requestUrl = `${getUtilitiesEndPoint()}deforestationlist?orgID=${orgID}`
		const { data } = yield call(request, requestUrl)
		yield put(TradeDocDuc.creators.setDeforestationEvents(data))
		yield put(AppDuc.creators.hideGlobalLoader('fetch-deforestation-alert'))
	} catch (err) {
		const { message } = err
		yield put(AppDuc.creators.hideGlobalLoader('fetch-deforestation-alert'))
		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchProduct(action = {}) {
	try {
		const { productID, fetchPrice, fetchTax } = action
		yield put(AuthDuc.creators.fetchProductsStatus(true))

		if (productID) {
			let pricesList
			let taxesList

			if (fetchPrice) {
				// fetch prices
				const requestUrl = `${getCoreEndPoint()}products/${productID}/price`
				const { data = {} } = yield CallWithRefreshCheck(requestUrl)

				pricesList = getIn(data, ['list']) || []
			}

			if (fetchTax) {
				// fetch prices
				const requestUrl = `${getCoreEndPoint()}products/${productID}/tax`
				const { data = {} } = yield CallWithRefreshCheck(requestUrl)

				taxesList = getIn(data, ['list']) || []
			}

			const requestUrl = `${getCoreEndPoint()}products/${productID}`
			const { data = {} } = yield CallWithRefreshCheck(requestUrl)

			if (data.id)
				yield put(
					AppDuc.creators.fetchAProductSuccess(
						data.id,
						data,
						pricesList,
						taxesList
					)
				)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(AuthDuc.creators.fetchProductsStatus(false, true))
	} finally {
		yield put(AuthDuc.creators.fetchProductsStatus(false))
	}
}

const transformDocumentForState = (response = {}, parentDocResponse = {}) => {
	let readOnlySections = []
	let parentParsedSchema = {}

	const baseSchema = {
		meta: {},
		shipping: {},
		supplyChainModel: {},
		products: [],
		additionalInfo: {},
		remarks: [],
		authorization: {},
		targetProducts:
			response && response.targetProducts ? response.targetProducts : [],
	}
	const docStatus = getIn(response, ['status', 'state'])
	if (docStatus) baseSchema.docStatus = docStatus

	if (getIn(response, ['receiverTraceIDs'])) {
		baseSchema.receiverTraceIDs = getIn(response, ['receiverTraceIDs'])
	}

	if (!isEmptyObject(parentDocResponse)) {
		const { baseSchema: _baseSchema } = transformDocumentForState(
			parentDocResponse
		)

		parentParsedSchema = _baseSchema

		readOnlySections = addLast(
			readOnlySections,
			Object.keys(parentParsedSchema)
		)
	}

	const meta =
		getIn(response, ['meta']) || getIn(parentDocResponse, ['meta']) || {}
	const parentType = getIn(response, ['type']) || ''
	if (meta) {
		baseSchema.meta = meta
		baseSchema.meta.entityReference = getIn(response, ['number']) || ''
		const traceGroupID = getIn(response, ['traceGroupID'])
		if (traceGroupID) {
			baseSchema.meta.traceGroupID = traceGroupID
		}
		const traces = getIn(response, ['traces'])
		if (traces) {
			baseSchema.meta.traces = traces
		}
		if (docStatus === 'draft') {
			baseSchema.meta.issueDate = new Date()
			baseSchema.meta.expectedDeliveryDate = new Date()
		}

		if (
			parentType &&
			parentType !== 'plantation' &&
			docStatus !== 'draft'
		) {
			baseSchema.meta.referenceName = getIn(response, ['type'])
			baseSchema.meta.referenceValue = getIn(response, ['id'])
		}
		if (parentType === 'plantation') {
			baseSchema.meta.ticketGroupID = getIn(response, ['id'])
			// baseSchema.meta.binDetails = binDetails
		}
	} else if (parentParsedSchema.meta) {
		baseSchema.meta = parentParsedSchema.meta
	}

	const shipping = getIn(response, ['meta', 'shipping'])
	if (shipping) {
		baseSchema.shipping = shipping
	} else if (parentParsedSchema.shipping) {
		baseSchema.shipping = parentParsedSchema.shipping
	}
	const products = getIn(response, ['products'])

	if (products) {
		// If ProductID is same then merge quantity and show as one row
		const fetchProducts = {}

		products.forEach((product, index) => {
			const isSameProductID = fetchProducts[product.id]
			// eslint-disable-next-line no-param-reassign
			product = { ...product, activeIndex: index }
			// eslint-disable-next-line no-param-reassign
			product.count =
				getIn(response, ['meta', 'quantityNonMetric']) ||
				getIn(product, ['count']) ||
				0

			// eslint-disable-next-line no-param-reassign

			// eslint-disable-next-line no-param-reassign
			product._submitted = true
			if (isSameProductID)
				// eslint-disable-next-line no-param-reassign
				product.quantity =
					(isSameProductID.quantity || 0) + (product.quantity || 0)
			// eslint-disable-next-line no-param-reassign
			product.certification =
				getIn(products, [0, 'certification']) || 'uncertified'
			// eslint-disable-next-line no-param-reassign
			product.supplyChainModel =
				getIn(products, [0, 'supplyChainModel']) || 'none'

			// eslint-disable-next-line no-param-reassign
			fetchProducts[product.id] = product
		})

		baseSchema.products = Object.values(fetchProducts)

		const isVirtualInventoryAvailable =
			products.filter(pr => pr.storageUnitID === undefined).length > 0

		const isNotVirtualInventory = products.filter(
			pr => pr.storageUnitID !== undefined
		)

		baseSchema.individualProductEntry = isVirtualInventoryAvailable
			? isNotVirtualInventory
			: products
	} else if (parentParsedSchema.products) {
		baseSchema.products = parentParsedSchema.products
	}

	const remarks = getIn(response, ['remarks'])

	if (remarks) {
		baseSchema.remarks = remarks

		// if its a draft, keep the latest one
		if (docStatus === 'draft')
			baseSchema.additionalInfo = setIn(
				baseSchema.additionalInfo,
				['remarks'],
				getIn(remarks.shift(), ['comment'])
			)
	}

	const authorization = getIn(response, ['meta', 'authorization'])

	if (authorization) {
		baseSchema.authorization = authorization
	}

	const attachments = getIn(response, ['meta', 'files'])

	const supplyChainModel = getIn(response, ['meta', 'supplyChainModel'])
	if (supplyChainModel) baseSchema.supplyChainModel = supplyChainModel

	if (attachments)
		baseSchema.additionalInfo = setIn(
			baseSchema.additionalInfo,
			['attachments'],
			attachments
		)

	const bankDetails = getIn(response, ['meta', 'other', 'bankDetails'])
	if (bankDetails) baseSchema.bankDetails = bankDetails

	const parentDocRef = getIn(response, ['meta', 'other', 'parentDocRef'])

	if (parentDocRef) {
		baseSchema.parentDocRef = parentDocRef
	} else if (!isEmptyObject(parentDocResponse)) {
		if (INITIAL_TYPES.includes(parentDocResponse.type)) {
			// when its standard doc, only keep imp references
			baseSchema.parentDocRef = pickBy(
				parentDocResponse,
				excludedReadableSections
			)
		} else {
			// when its non-standard doc, keep all attributes
			baseSchema.parentDocRef = parentDocResponse
		}
	}

	const currentDocHistoryReference = getIn(response, [
		'meta',
		'currentDocHistoryReference',
	])

	if (currentDocHistoryReference)
		baseSchema.currentDocHistoryReference = currentDocHistoryReference

	const readOnlySectionsInDoc =
		getIn(response, ['meta', 'other', 'readOnlySections']) || []

	// add valid reference meta
	baseSchema.type = response.type
	baseSchema.id = response.id
	baseSchema.docNumber = response.number
	baseSchema.updatedAt = response.updatedAt
	baseSchema.createdAt = response.createdAt

	// get all the involved parties
	baseSchema.initiatingPartyID =
		response.initiatingPartyID || parentParsedSchema.initiatingPartyID
	baseSchema.receivingPartyID =
		response.receivingPartyID || parentParsedSchema.receivingPartyID
	baseSchema.initiatingUserID =
		response.initiatingUserID || parentParsedSchema.initiatingUserID
	baseSchema.receivingUserID =
		response.receivingUserID || parentParsedSchema.receivingUserID
	baseSchema.initiatorTraceIDs =
		response.initiatorTraceIDs || parentParsedSchema.initiatorTraceIDs

	// filter out sections that we cannot inherit as read only
	readOnlySections = readOnlySections.filter(
		key => !excludedReadableSections.includes(key)
	)

	return {
		baseSchema: pickBy(baseSchema),
		readOnlySections: readOnlySectionsInDoc || readOnlySections,
	}
}
const transformDocumentForServer = (targetDoc = {}) => {
	const targetKeys = [
		'initiatingPartyID',
		'receivingPartyID',
		'initiatingUserID',
		'products',
		'type',
		'meta',
		'traceIds',
		'remarks',
	]

	const baseSchema = pick(targetDoc, targetKeys)
	// add this from other segments
	const remark = getIn(targetDoc, ['additionalInfo', 'remarks'])

	const targetRemark = {
		comment: remark,
		userID: targetDoc.initiatingUserID,
	}

	if (remark) {
		baseSchema.remarks = [targetRemark]
	}

	if (targetDoc.meta) {
		// Backend want local date/time with 00.00.00 time from frontend. earlier it was UTC
		const currentMeta = { ...targetDoc.meta }
		let currentIssueDate = currentMeta.issueDate
		// only if something selected in datepicker is string, default is new Date()
		if (typeof currentIssueDate === 'string') {
			// Removing time and adding 00.00.00
			currentIssueDate = `${currentIssueDate.split('T')[0]}T00:00:00.000Z`
			currentMeta.issueDate = currentIssueDate
		} else {
			// Default is not a string. JSON.stringify (line 2389) will convert this to UTC string. so converting it to string
			// Adding UTC time difference will make time 00.00.00
			currentIssueDate = new Date(currentMeta.issueDate).setHours(
				23,
				59,
				59,
				999
			)
			const currentISODate = new Date(currentIssueDate).toISOString()
			currentMeta.issueDate = `${
				currentISODate.split('T')[0]
			}T00:00:00.000Z`
		}
		baseSchema.meta = currentMeta
		baseSchema.number = targetDoc.meta.entityReference
	}

	if (targetDoc.draftID) {
		baseSchema.id = targetDoc.id
	}

	if (targetDoc.traceGroupID) {
		if (targetDoc.parentDocRef.type === 'storage') {
			baseSchema.traceGroupID = targetDoc.traceGroupID
			baseSchema.products = []
		}
	}
	if (targetDoc.parentDocRef.type === 'plantation') {
		baseSchema.products = targetDoc.parentDocRef.traces
		baseSchema.isPlantation = 'plantation'
	}

	return baseSchema
}

function* fetchDocumentPartipants({ initiatingPartyID, receivingPartyID }) {
	try {
		if (initiatingPartyID || receivingPartyID) {
			yield put(
				AuthDuc.creators.fetchOrgDetails(
					[initiatingPartyID, receivingPartyID].filter(a => a),
					false,
					true
				)
			)
		}

		const targetParticipantProfiles = {}
		// sync the participants
		yield put(
			TradeDocDuc.creators.setDocumentParticipants(
				targetParticipantProfiles
			)
		)
	} catch (e) {
		logger.log(e)
		throw e
	}
}

function* fetchActiveDocument(action) {
	const { returnValue } = action
	try {
		const {
			rootModule,
			entityID,
			fetchDraft,
			documentStatus,
			baseValueSchema,
		} = action || {}
		yield put(TradeDocDuc.creators.documentLoading(true))
		const currentUser = yield getUserProfileFromLoggedUser()
		const { fullName, orgRole } = currentUser
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		if (entityID) {
			let requestUrl = `${getCoreEndPoint()}entities/${entityID}`
			if (selectedCPID) {
				requestUrl = `${getCoreEndPoint()}entities/${entityID}/childorg/${selectedCPID}`
			}

			if (fetchDraft) requestUrl += '/draft'
			const { data = {} } = yield CallWithRefreshCheck(requestUrl)
			yield put(
				TradeDocDuc.creators.setBaseProduct(
					getIn(data, ['products']) || []
				)
			)
			if (Object.keys(data).length === 0) {
				yield put(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
						{
							rootModule: 'outgoing',
						}
					)
				)
			}
			if (
				documentStatus === 'edit' ||
				(baseValueSchema && baseValueSchema.fromDraft)
			) {
				if (getIn(baseValueSchema, ['targetProducts'])) {
					data.targetProducts = getIn(baseValueSchema, [
						'targetProducts',
					])
				}
				if (
					getIn(baseValueSchema, ['meta', 'docReference']) &&
					getIn(baseValueSchema, ['meta', 'docReference']).length > 0
				) {
					data.meta.docReference = getIn(baseValueSchema, [
						'meta',
						'docReference',
					])
				} else if (data.meta.docReference) {
					delete data.meta.docReference
				}

				if (getIn(baseValueSchema, ['meta', 'brokerEmailID'])) {
					if (data.meta.brokerEmailID) {
						data.meta.brokerEmailID = getIn(baseValueSchema, [
							'meta',
							'brokerEmailID',
						])
					} else {
						data.meta = {
							...data.meta,
							brokerEmailID: getIn(baseValueSchema, [
								'meta',
								'brokerEmailID',
							]),
						}
					}
				} else if (data.meta.brokerEmailID) {
					delete data.meta.brokerEmailID
				}

				if (getIn(baseValueSchema, ['meta', 'receiverEmailID'])) {
					if (data.meta.receiverEmailID) {
						data.meta.receiverEmailID = getIn(baseValueSchema, [
							'meta',
							'receiverEmailID',
						])
					} else {
						data.meta = {
							...data.meta,
							receiverEmailID: getIn(baseValueSchema, [
								'meta',
								'receiverEmailID',
							]),
						}
					}
				} else if (data.meta.receiverEmailID) {
					delete data.meta.receiverEmailID
				}

				if (getIn(baseValueSchema, ['meta', 'issueDate'])) {
					data.meta.issueDate = getIn(baseValueSchema, [
						'meta',
						'issueDate',
					])
				}

				if (
					getIn(baseValueSchema, ['products']) &&
					getIn(baseValueSchema, ['products']).length > 0
				) {
					data.products = getIn(baseValueSchema, ['products'])
				} else if (data.products) {
					delete data.products
				}

				if (
					getIn(baseValueSchema, ['meta', 'attachedEntity']) &&
					getIn(baseValueSchema, ['meta', 'attachedEntity', 'id'])
				) {
					data.meta.attachedEntity = getIn(baseValueSchema, [
						'meta',
						'attachedEntity',
					])
				}

				if (getIn(baseValueSchema, ['meta', 'entityReference'])) {
					data.meta.entityReference = getIn(baseValueSchema, [
						'meta',
						'entityReference',
					])
				}

				if (getIn(baseValueSchema, ['meta', 'supplyChainModel'])) {
					data.meta.supplyChainModel = getIn(baseValueSchema, [
						'meta',
						'supplyChainModel',
					])
				}
				if (
					!getIn(baseValueSchema, ['meta', 'attachmentFlow']) &&
					getIn(baseValueSchema, ['meta', 'files']) &&
					getIn(baseValueSchema, ['meta', 'files']).length > 0
				) {
					data.meta.files = getIn(baseValueSchema, ['meta', 'files'])
				} else if (getIn(data, ['meta', 'files'])) {
					delete data.meta.files
				}

				if (
					!getIn(baseValueSchema, ['meta', 'attachmentFlow']) &&
					getIn(baseValueSchema, ['additionalInfo', 'remarks'])
				) {
					const targetRemark = {
						comment: getIn(baseValueSchema, [
							'additionalInfo',
							'remarks',
						]),
						userID: getIn(baseValueSchema, ['initiatingUserID']),
						// type: 'misc',
						// organizationID: getIn(baseValueSchema, [
						// 'initiatingPartyID',
						// ]),
						// createdAt: getIn(baseValueSchema, ['createdAt']),
					}
					data.remarks = [targetRemark]
				} else if (
					getIn(data, ['additionalInfo']) ||
					getIn(data, ['remarks'])
				) {
					delete data.remarks
					delete data.additionalInfo
				}

				if (getIn(baseValueSchema, ['authorization'])) {
					data.authorization = getIn(baseValueSchema, [
						'authorization',
					])
				} else if (getIn(data, ['authorization'])) {
					delete data.authorization
				}
				if (getIn(baseValueSchema, ['meta', 'authorization'])) {
					data.meta.authorization = getIn(baseValueSchema, [
						'meta',
						'authorization',
					])
				} else if (getIn(data, ['meta', 'authorization'])) {
					delete data.meta.authorization
				}

				if (getIn(baseValueSchema, ['meta', 'expectedDeliveryDate'])) {
					data.meta.expectedDeliveryDate = getIn(baseValueSchema, [
						'meta',
						'expectedDeliveryDate',
					])
				} else if (getIn(data, ['meta', 'expectedDeliveryDate'])) {
					delete data.meta.expectedDeliveryDate
				}

				if (
					!getIn(baseValueSchema, ['meta', 'attachmentFlow']) &&
					getIn(baseValueSchema, ['meta', 'shipping'])
				) {
					data.shipping = getIn(baseValueSchema, ['shipping'])
					data.meta.shipping = getIn(baseValueSchema, [
						'meta',
						'shipping',
					])
				} else if (getIn(data, ['meta', 'shipping'])) {
					delete data.meta.shipping
					delete data.shipping
				}

				if (getIn(baseValueSchema, ['meta', 'transporter'])) {
					data.meta.transporter = getIn(baseValueSchema, [
						'meta',
						'transporter',
					])
				} else if (getIn(data, ['meta', 'transporter'])) {
					delete data.meta.transporter
				}

				if (data.docNumber) {
					data.docNumber =
						getIn(baseValueSchema, ['meta', 'entityReference']) ||
						data.docNumber
				}

				if (data.number) {
					data.number =
						getIn(baseValueSchema, ['meta', 'entityReference']) ||
						data.number
				}
			}
			if (!data) {
				throw new Error('Unable to fetch the response')
			}

			if (returnValue) {
				return data
			}

			// fetch the relevant entity references
			const { initiatingPartyID, receivingPartyID } = data
			// need to check if initiating & receiving user is required or not
			yield fetchDocumentPartipants({
				initiatingPartyID,
				receivingPartyID,
			})

			/** If the user org is not participating, block the user */
			const orgID = yield getOrgIDFromLoggedUser()
			if (
				(orgID &&
					!selectedCPID &&
					![initiatingPartyID, receivingPartyID].includes(orgID)) ||
				(selectedCPID &&
					![initiatingPartyID, receivingPartyID].includes(
						selectedCPID
					))
			) {
				throw new Error('User is not authenticated')
			}

			const receiverTraceIDs = getIn(data, ['receiverTraceIDs'])

			yield put(
				TradeDocDuc.creators.setEntityReceiverTraceIDs(receiverTraceIDs)
			)

			// successfully done
			const { baseSchema, readOnlySections } = transformDocumentForState(
				data
			)
			if (fetchDraft) {
				baseSchema.meta.authorization = {
					reportedBy: fullName,
					reportedByRole: orgRole,
					authorisedSignatory: fullName,
				}
			}
			// update the document status in store

			yield put(
				TradeDocDuc.creators.setActiveDocument({
					rootModule,
					baseSchema,
					readOnlySections,
				})
			)

			yield put(TradeDocDuc.creators.initiateDocumentStep())
		} else {
			throw new Error('Not a valid request')
		}
	} catch (e) {
		if (returnValue) {
			throw e
		}
		logger.log(e)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	}
}

function* fetchParentSource(action) {
	try {
		const { rootModule, parentDocRef } = action || {}

		const [parentType, _parentDocRef] = parentDocRef.split('~')
		const shouldFetchParentDoc = parentType && _parentDocRef
		if (shouldFetchParentDoc) {
			yield put(TradeDocDuc.creators.documentLoading(true))

			let parentDoc = {}

			if (INITIAL_TYPES.includes(parentType)) {
				// our regular document
				parentDoc = yield fetchActiveDocument({
					returnValue: true,
					rootModule: parentType,
					entityID: _parentDocRef,
				})
			} else {
				// call the document types based on what's sent as reference
				parentDoc = yield fetchSupportiveDocument({
					parentType,
					parentDocRef: _parentDocRef,
					returnValue: true,
				})
			}
			if (parentDoc) {
				// successfully done
				const {
					baseSchema,
					readOnlySections,
				} = transformDocumentForState({}, parentDoc)

				yield put(
					TradeDocDuc.creators.setActiveDocument({
						rootModule,
						baseSchema,
						readOnlySections:
							baseSchema.type === rootModule // if same type, then its a clone and not inheriting
								? []
								: readOnlySections,
					})
				)
				yield put(TradeDocDuc.creators.initiateDocumentStep())
			} else {
				throw new Error('Unable to fetch the linked source')
			}
		}
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		logger.log(e)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	}
}

function* fetchSupportiveDocument(action = {}) {
	const { returnValue } = action
	try {
		yield put(TradeDocDuc.creators.documentLoading(true))
		const { parentType, parentDocRef } = action

		let document = {}

		if (parentType === 'work-entry') {
			const requestUrl = `${getPlantationEndPoint()}plantation/tickets/${parentDocRef}`

			const response = yield CallWithRefreshCheck(requestUrl)

			document = getIn(response, ['data']) || {}

			// temporary should be delete when tkt-read returns product information
			const readProducturl = `${getCoreEndPoint()}clients/organizations/${
				document.organizationID
			}/products/${document.meta.productID}`
			const productResponse = yield CallWithRefreshCheck(readProducturl)
			const productInfo = getIn(productResponse, ['data']) || {}

			document.products = [
				{
					id: productInfo.product.id,
					quantity: document.ffbCount,
					code: productInfo.product.code,
					name: productInfo.product.name,
					description: productInfo.product.description,
					uom: productInfo.product.uom,
				},
			]

			if (document.id && !document.type) {
				document.type = parentType
			}

			// if doc has organizationID fetch it
			if (document.organizationID || document.createdBy) {
				yield fetchDocumentPartipants({
					initiatingPartyID: document.organizationID,
				})
				yield put(
					AuthDuc.creators.fetchOrgDetails(
						[document.organizationID],
						false,
						true
					)
				)
			}
		} else if (parentType === 'storage') {
			const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${parentDocRef}`
			const response = yield CallWithRefreshCheck(requestUrl)
			document = getIn(response, ['data']) || {}
			const combineProducts = {}

			document.products = Object.values(combineProducts)
			document.traceGroupID = parentDocRef
			if (document.id && !document.type) {
				document.type = parentType
			}

			// if doc has organizationID fetch it
			if (document.organizationID || document.createdBy) {
				yield fetchDocumentPartipants({
					initiatingPartyID: document.organizationID,
				})
				yield put(
					AuthDuc.creators.fetchOrgDetails(
						[document.organizationID],
						false,
						true
					)
				)
			}
		} else if (parentType === 'plantation') {
			const orgID = yield getOrgIDFromLoggedUser()
			const requestProductUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=100`
			const productResponse = yield CallWithRefreshCheck(
				requestProductUrl
			)
			const productList = getIn(productResponse, ['data', 'list']) || []

			const requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups/${parentDocRef}`
			const { data } = yield CallWithRefreshCheck(requestUrl)
			document = data

			const totalQuantity = data.tickets
				.map(ticket => ticket.ffbCount)
				.reduce((ttl, ticket) => ticket + ttl)

			const traces = data.tickets.map((ticket, index) => {
				const traceID =
					getIn(data, ['tickets', index, 'meta', 'traceID']) || ''

				return {
					traceID,
					quantity: ticket.ffbCount,
				}
			})
			const productID =
				getIn(data, ['tickets', 0, 'meta', 'productID']) || ''

			const productInfo = productList.filter(
				product => product.product.id === productID
			)
			const selectedProduct = getIn(productInfo, [0, 'product']) || {}

			document.products = [
				{
					id: productID,
					code: selectedProduct.code,
					name: selectedProduct.name,
					description: selectedProduct.description || '',
					uom: selectedProduct.defaultUOM || selectedProduct.uom,
					count: totalQuantity,
				},
			]
			if (data.id) {
				document.type = parentType
				document.traces = traces
				document.name = data.name
				document.meta = {
					issueDate: new Date(),
					expectedDeliveryDate: new Date(),
				}
			}
			// if doc has organizationID fetch it
			if (data.organizationID || data.createdBy) {
				yield fetchDocumentPartipants({
					initiatingPartyID: data.organizationID,
				})
				yield put(
					AuthDuc.creators.fetchOrgDetails(
						[data.organizationID],
						false,
						true
					)
				)
			}
		}

		yield put(TradeDocDuc.creators.documentLoading(false))

		if (returnValue) return document
	} catch (e) {
		if (returnValue) throw e
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	}
}

function* saveDocument(action = {}) {
	try {
		const {
			document,
			rootModule,
			currentDocId,
			successMsg,
			poSuccessMsg,
			invoiceSuccessMsg,
			doSuccessMsg,
			actionType = 'submit',
			fromPlantation = false,
			rootType = 'incoming',
			fromDocumentListing = false,
		} = action.documentPropsList

		const location = yield select(TradeDocDuc.selectors.location)
		const toSave = actionType === 'save'
		const toSubmit = !toSave
		const toUpdate =
			getIn(location, ['payload', 'documentStatus']) === 'clone' ||
			getIn(location, ['payload', 'documentStatus']) === 'edit'
		const currentIDRef =
			currentDocId || getIn(location, ['payload', 'documentReference'])
		yield put(TradeDocDuc.creators.documentLoading(toSubmit, toSave))

		const parentDocRef = yield select(
			TradeDocDuc.selectors.getDocumentParentDocRef
		)
		const readOnlySections = yield select(
			TradeDocDuc.selectors.getDocumentReadOnlySections
		)

		const isDO = rootModule === 'delivery-order'
		const isExcelUploads = rootModule === 'excel-uploads'
		const isInvoice = rootModule === 'invoice'
		const isPO = rootModule === 'purchase-order'
		const isIncomingDo = rootModule === 'incoming-delivery-order'
		const targettingParty = isIncomingDo
			? yield select(AuthDuc.selectors.getCurrentOrganization)
			: yield select(TradeDocDuc.selectors.getDocumentActivePartner)
		const initiatingParty = isIncomingDo
			? yield select(TradeDocDuc.selectors.getDocumentActivePartner)
			: yield select(AuthDuc.selectors.getCurrentOrganization)
		const initiatingUserID = yield select(AuthDuc.selectors.getClientID)

		const activeDocument = { ...document }
		if (isDO) {
			activeDocument.products =
				document.targetProducts && document.targetProducts.length > 0
					? [...document.targetProducts]
					: activeDocument.products

			delete activeDocument.targetProducts
		}

		const transformedRequest = transformDocumentForServer({
			...activeDocument,
			parentDocRef,
			type: rootModule,
			readOnlySections,
			initiatingUserID,
			initiatingPartyID: toUpdate
				? document.initiatingPartyID
				: initiatingParty.id,
			receivingPartyID: toUpdate
				? document.receivingPartyID
				: targettingParty.id,
			currentDocHistoryReference: currentIDRef,
			draftID: toSubmit && currentIDRef,
		})

		let requestUrl =
			transformedRequest.isPlantation === 'plantation'
				? `${getPlantationEndPoint()}entities`
				: `${getCoreEndPoint()}entities`

		const isEntity = toUpdate
			? `update-${transformedRequest.type}`
			: `create-${transformedRequest.type}`

		const event =
			transformedRequest.isPlantation === 'plantation'
				? 'entity-on-ticketgroup'
				: isEntity

		const isDraftUpdate =
			toSave &&
			document.id === currentIDRef &&
			getIn(document, ['docStatus']) === 'draft'

		if (currentIDRef && isDraftUpdate)
			requestUrl += `/${currentIDRef}/draft`

		const options = {
			method:
				(currentIDRef && isDraftUpdate) ||
				(toSubmit &&
					toUpdate &&
					transformedRequest.meta.directInventory)
					? 'PUT'
					: 'POST',
			body: JSON.stringify(transformedRequest),
		}
		if (toSubmit) {
			if (
				fromPlantation &&
				isDO &&
				(!toUpdate ||
					(toUpdate && transformedRequest.meta.directInventory))
			) {
				requestUrl = `${getPlantationEndPoint()}plantation/entity`
				options.body = JSON.stringify(transformedRequest)
			} else if (isIncomingDo) {
				requestUrl = `${getCoreEndPoint()}entities/events/incoming-entity-by-initiator`
				options.body = JSON.stringify(transformedRequest)
			} else if (isExcelUploads) {
				requestUrl =
					rootType === 'incoming' || fromPlantation
						? `${getCoreEndPoint()}entities/events/incoming-entity-by-initiator-multi`
						: `${getCoreEndPoint()}entities/events`
				const finalPayload = []
				if (document?.length > 0) {
					document.forEach(item => {
						finalPayload.push({
							event: 'create-delivery-order',
							data: {
								payload: item,
							},
						})
					})
				}
				if (rootType === 'incoming' || fromPlantation) {
					options.body = JSON.stringify({ entities: document })
				} else {
					options.body = JSON.stringify({
						tenantID: initiatingParty.id,
						events: finalPayload,
					})
				}
			} else {
				requestUrl = `${requestUrl}/events`
				options.body = JSON.stringify({
					tenantID: initiatingParty.id,
					events: [
						{
							event,
							data: { payload: transformedRequest },
						},
					],
				})
			}
		}

		const { data = {} } = yield CallWithRefreshCheck(requestUrl, options)

		let ID
		if (toSubmit) {
			ID = getIn(data, ['events', 0, 'data', 'payload', 'id'])
		} else {
			ID = getIn(data, ['id'])
		}
		if (ID || isExcelUploads) {
			// successfully done
			const {
				baseSchema,
				readOnlySections: _readOnlySections,
			} = transformDocumentForState(data)

			// update the document status in store
			yield put(
				TradeDocDuc.creators.setActiveDocument({
					rootModule,
					baseSchema,
					_readOnlySections,
				})
			)

			// save the document with reference
			if (toSave) {
				yield put(TradeDocDuc.creators.setOutgoingActiveTab('draft'))
				yield put(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
						{
							rootModule: 'outgoing',
						}
					)
				)
				yield Toast({
					type: 'success',
					message: successMsg,
				})
			} else if (toSubmit) {
				if (rootModule === 'excel-uploads') {
					yield put(
						TradeDocDuc.creators.setUploadDODraftAcceptedList(
							null,
							{}
						)
					)
					yield put(
						TradeDocDuc.creators.setDODraftAcceptAllLoading(false)
					)
					if (fromDocumentListing) {
						yield put(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$LISTING,
								{
									rootModule: rootType,
									submodule: rootModule,
								}
							)
						)
					} else {
						yield put(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
								{
									rootModule: rootType,
								}
							)
						)
					}
				} else {
					yield put(
						MainRouteDuc.creators.redirect(
							MainRouteDuc.types
								.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
							{
								rootModule,
								documentReference: ID,
							},
							location.query,
							{
								forceScrolltoTop: true,
							}
						)
					)
				}
				yield put(TradeDocDuc.creators.setActiveTab('original'))
			}
			yield put(
				TradeDocDuc.creators.flushStorageDetailsBasedOnSupplyChainModel()
			)
			if (isPO && !toSave) {
				yield Toast({
					type: 'success',
					message: poSuccessMsg,
				})
			} else if (isInvoice && !toSave) {
				yield Toast({
					type: 'success',
					message: invoiceSuccessMsg,
				})
			} else if ((isDO || isExcelUploads) && !toSave) {
				yield Toast({
					type: 'success',
					message: doSuccessMsg,
				})
			}
		}
	} catch (e) {
		const { message } = e

		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	} finally {
		yield put(TradeDocDuc.creators.documentLoading(false, false))
	}
}

// POST request that actually creates a WB slip
function* createEntityAttachment(action) {
	try {
		const {
			rootModule,
			entityID,
			files,
			toastMessage,
			attachmentType,
		} = action

		yield put(AppDuc.creators.showGlobalLoader('create-weighBridge'))

		const attachmentDetails = {
			entityID,
			type: 'user-input',
			file: files[0],
			meta: {
				category:
					attachmentType && attachmentType === 'bill-of-lading'
						? 'bill-of-lading'
						: 'documents',
			},
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments`
		const options = {
			method: 'POST',
			body: JSON.stringify(attachmentDetails),
		}
		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule,
					documentReference: data.entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-weighBridge'))
	}
}

function* updateEntityAttachment(action) {
	try {
		const {
			rootModule,
			attachmentID,
			entityID,
			files,
			toastMessage,
		} = action

		const attachmentDetails = {
			entityID,
			file: files,
		}

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(attachmentDetails),
		}
		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule,
					documentReference: entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* createWBSlip(action) {
	try {
		yield createWeighBridgeSlip(action)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* deleteEntityAttachment(action) {
	try {
		const { rootModule, attachmentID, entityID, toastMessage } = action

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule,
					documentReference: entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchTimelineData(action) {
	try {
		const { tradeId } = action
		const requestUrl = `${getCoreEndPoint()}entities/tradeid/${tradeId}/states`
		const options = {
			method: 'GET',
		}
		const response = yield call(request, requestUrl, options)

		// update the document status in store
		let userString = ''
		let orgString = ''
		if (response && response.data && response.data.length > 0) {
			const userArray = []
			const orgArray = []
			response.data.forEach(item => {
				if (item && item.userID && item.userID.includes('@')) {
					const user = item.userID.split('@')
					userArray.push(user[0])
				} else {
					userArray.push(item.userID)
				}

				orgArray.push(item && item.createdBy)
			})
			if (userArray.length > 1) {
				userString = String(userArray)
				orgString = String(orgArray)
			} else if (userArray.length === 1) {
				userString = userArray[0] && userArray[0].toString()
				orgString = orgArray[0] && orgArray[0].toString()
			}
		}
		const userListUrl = `${getIAMEndPoint()}clients/users/list/no-pagination?orgIDs=${orgString}&userIDs=${userString}`
		const userResponse = yield call(request, userListUrl, options)
		const finalResponse = []
		response.data.forEach(item => {
			const user = item.userID.split('@')
			const data =
				userResponse &&
				userResponse.data.find(key => key.id === user[0])
			finalResponse.push({ ...item, userName: data ? data.fullName : '' })
		})

		yield put(TradeDocDuc.creators.setTimelineData(finalResponse))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addNewRemark(action) {
	try {
		const { remarkType, remark, currentDocId } = action
		const requestUrl = `${getCoreEndPoint()}entities/${currentDocId}/remarks`
		yield put(TradeDocDuc.creators.documentLoading(true))
		const currentUser = yield getUserProfileFromLoggedUser()

		const body = remark
			? [
					{
						userID: currentUser.id,
						organizationID: getIn(currentUser, [
							'organization',
							'id',
						]),
						comment: remark,
						type: remarkType,
					},
			  ]
			: [
					{
						userID: currentUser.id,
						organizationID: getIn(currentUser, [
							'organization',
							'id',
						]),
						type: remarkType,
					},
			  ]
		const options = {
			method: 'PUT',
			body: JSON.stringify(body),
		}
		const { data = [] } = yield CallWithRefreshCheck(requestUrl, options)

		// update the document status in store
		yield put(
			TradeDocDuc.creators.setRemarksInActiveDocument(currentDocId, data)
		)
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		logger.log(e)
		const { message = 'Unable to update remark. Please try again.' } = e
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false))
	}
}

const transformReportForState = (response = [], orgList = []) => {
	const responseArray = response.map(res => {
		const baseSchema = {
			issuedTo:
				orgList.filter(
					obj => obj.id === `${getIn(res, ['receivingPartyID'])}`
				).length !== 0
					? orgList.filter(
							obj =>
								obj.id === `${getIn(res, ['receivingPartyID'])}`
					  )[0].name
					: '---',
			orgCategory:
				orgList.filter(
					obj => obj.id === `${getIn(res, ['receivingPartyID'])}`
				).length !== 0
					? orgList.filter(
							obj =>
								obj.id === `${getIn(res, ['receivingPartyID'])}`
					  )[0].categories[0].name
					: '---',

			transaction: [
				{ date: getDateByFormat(getIn(res, ['updatedAt'])) || '---' },
				{ contract: '---' },
				{ doNumber: getIn(res, ['number']) || '---' },
				{ ticket: '---' },
			],
			product: [
				{
					productName:
						`${getIn(res, ['products', 0, 'name'])}` || '---',
				},

				{
					quantity:
						`${getIn(res, ['products', 0, 'quantity'])} ${getIn(
							res,
							['products', 0, 'uom']
						)} ` || '---',
				},
				{ binNumber: '---' },
				{ blockNo: '---' },
				{ fieldNo: '---' },
			],
			transporter: [
				{
					driverName:
						getIn(res, ['meta', 'transporter', 'driverName']) ||
						'---',
				},
				{
					vehicleNumber:
						getIn(res, ['meta', 'transporter', 'vehicleNumber']) ||
						'---',
				},
				{
					vehicleType: '---',
				},
				{
					dispatchTime:
						getDateByFormat(getIn(res, ['createdAt'])) || '---',
				},
				{
					deliveredTime:
						getDateByFormat(getIn(res, ['updatedAt'])) || '---',
				},
			],
		}

		return baseSchema
	})

	return {
		baseSchema: responseArray,
	}
}

function* appendAuditReport(action) {
	try {
		const { locationState = {}, skipGlobalLoader } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('audit-reports'))
		const { query } = locationState
		const existingQueryFromUrl = query || {}
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: existingQueryFromUrl.limit,
			nextIndex: existingQueryFromUrl.nextIndex,
		}
		// prepare backend query based on the pagination factors
		// using activeIndex to set limit
		if (paginationQuery.limit) {
			backendTargetQuery.limit =
				paginationQuery.limit * (1 + paginationQuery.activeIndex)
		}
		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}
		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}

		const __query = {
			...backendTargetQuery,
		}

		// need to add the status of delivery order to 'delivered'
		const requestUrl = `${getCoreEndPoint()}entities?type=delivery-order&${querySerializer.stringify(
			{
				...__query,
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(
				getIn(origResponse, ['data', 'pageSize']) || PAGINATION_LIMIT
			),
			nextIndex: getIn(origResponse, ['data', 'prevStartID']),
		} // extract pagination queries from response
		yield put(
			TradeDocDuc.creators.setAuditPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(origResponse, ['data', 'pageSize']),
				getIn(origResponse, ['data', 'total']),
				getIn(origResponse, ['data', 'nextStartID']),
				getIn(origResponse, ['data', 'prevStartID'])
			)
		)
		// extract filter queries
		const { stateTree } = extractFilterQueries(response)
		yield put(TradeDocDuc.creators.setActiveAuditFilters(stateTree))

		// get all orgIDs
		const orgIDList = response.list.map(org => org.receivingPartyID)

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations?limit=100&id=${orgIDList.join(
			','
		)}`
		const { data: orgData = {} } = yield CallWithRefreshCheck(orgDetailsUrl)
		const orgDetails = getIn(orgData, ['list'])

		const { baseSchema } = transformReportForState(
			response.list,
			orgDetails
		)

		// successfully done
		yield put(TradeDocDuc.creators.fetchAuditReportSuccess(baseSchema))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('audit-reports'))
	}
}

function* fetchAuditReport(action) {
	try {
		yield put(TradeDocDuc.creators.flushAuditReport())
		const { locationState = {} } = action
		// if (!skipGlobalLoader)
		yield put(AppDuc.creators.showGlobalLoader('audit-reports'))

		const { query } = locationState

		const existingQueryFromUrl = query || {}
		let backendTargetQuery = {} // this would go to backend api call
		backendTargetQuery = merge(
			backendTargetQuery,
			transformFilterStringsToBEQueries(existingQueryFromUrl)
		)
		const __query = {
			...backendTargetQuery,
		}
		const requestUrl = `${getCoreEndPoint()}entities?type=delivery-order&${querySerializer.stringify(
			{
				...__query,
			}
		)}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []

		// get all orgIDs
		const orgIDList = response.list.map(org => org.receivingPartyID)

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations?id=${orgIDList.join(
			','
		)}`
		const { data: orgData = {} } = yield CallWithRefreshCheck(orgDetailsUrl)
		const orgDetails = getIn(orgData, ['list'])

		const { baseSchema } = transformReportForState(
			response.list,
			orgDetails
		)

		const certificationsURL = `${getIAMEndPoint()}clients/organizations/-/documents`
		const certResponse = yield CallWithRefreshCheck(certificationsURL)

		const certificateList = (
			getIn(certResponse, ['data', 'list']) || []
		).filter(cert => getIn(cert, ['type']) === 'certificate')

		// extract pagination queries from response
		yield put(
			TradeDocDuc.creators.setAuditPaginationEntries(
				0, // ActiveIndex
				getIn(origResponse, ['data', 'pageSize']),
				getIn(origResponse, ['data', 'total']),
				getIn(origResponse, ['data', 'nextStartID']),
				getIn(origResponse, ['data', 'prevStartID'])
			)
		)

		// extract filter queries
		const { stateTree } = extractFilterQueries(response)

		yield put(TradeDocDuc.creators.setActiveAuditFilters(stateTree))

		// successfully done
		yield put(TradeDocDuc.creators.fetchAuditReportSuccess(baseSchema))
		yield put(TradeDocDuc.creators.setCertificateDetails(certificateList))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('audit-reports'))
	}
}

function* saveAuditReportAsPDF(action) {
	try {
		yield put(TradeDocDuc.creators.flushAuditReport())

		const { filterValue, skipGlobalLoader } = action

		if (!skipGlobalLoader)
			yield put(
				AppDuc.creators.showGlobalLoader('audit-reports-download')
			)

		const requestUrl = `${getPlantationEndPoint()}reports/pdf?startDate=${
			filterValue.startDate
		}&endDate=${filterValue.endDate}`

		const temp = document.createElement('a')
		temp.setAttribute('download', 'auditreport.pdf')
		temp.setAttribute('href', requestUrl)
		temp.target = '_blank'
		document.body.appendChild(temp)
		temp.click()
		document.body.removeChild(temp)
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('audit-reports-download'))
	}
}

function* fetchDealerGeneralReports(action) {
	try {
		const { filters } = action
		const { startDate, endDate } = filters
		const requestUrl = `${getPlantationEndPoint()}reports/ffbdealergeneral?startDate=${startDate}&endDate=${endDate}`
		yield put(TradeDocDuc.creators.documentLoading(true))
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(TradeDocDuc.creators.setDealerGeneralReports(data))
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* searchEntityRef(action) {
	try {
		const { query, docType } = action

		const requestUrl = `${getCoreEndPoint()}entities/search-query/${query}?type=${docType}&sortby=asec`

		if (query.length < 3) {
			yield put(
				TradeDocDuc.creators.setEntityRefDetails({
					results: { [docType]: [] },
					refMessage: {
						[docType]:
							'expected atleast 3 characters in search query',
					},
				})
			)

			return
		}

		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data', 'list']) || []

		// const productMap = list.reduce((agg, prod) => {
		// 	const aggregator = agg
		// 	aggregator[prod.id] = prod

		// 	return aggregator
		// }, {})

		// push the org list to the state
		yield put(
			TradeDocDuc.creators.setEntityRefDetails({
				results: { [docType]: list },
				refMessage: {
					[docType]:
						list.length === 0 ? 'No Entity Reference found' : '',
				},
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* deleteDraftEntity(action) {
	try {
		const { entityID, toastMessage } = action
		const requestUrl = `${getPlantationEndPoint()}entities/${entityID}/draft`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'outgoing',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchProductDetailsBasedOnSupplyChainModel(action) {
	try {
		const {
			productID,
			supplyChainModel,
			certificationType,
			reservedFor,
			actor,
			prodList,
			productOwner,
		} = action
		yield put(TradeDocDuc.creators.setStorageTankLoadingStatus(true))

		const inventoryType =
			actor.startsWith('palmoil_trader') ||
			actor.startsWith('palmoil_manufacturer') ||
			actor.startsWith('rice-trader') ||
			actor.startsWith('palmoil_storage')
				? 'incoming'
				: 'outgoing'

		let requestUrl = `${getCoreEndPoint()}clients/organizations/-/products/${productID}/
		stats?supplyChainModel=${supplyChainModel}&inventoryType=${inventoryType}&certification=${certificationType}`

		if (
			!actor.startsWith('palmoil_trader') &&
			!actor.startsWith('palmoil_manufacturer') &&
			!actor.startsWith('rice-trader') &&
			!actor.startsWith('palmoil_storage')
		) {
			requestUrl += `&reservedFor=${reservedFor}`
		}

		if (actor.startsWith('palmoil_storage')) {
			requestUrl += `&productFor=${reservedFor}&productOwner=${productOwner}`
		}

		const { data } = yield CallWithRefreshCheck(requestUrl)
		const finalList = data?.list?.length > 0 ? data.list : false

		if (prodList && prodList.length > 0) {
			let newArray = []
			if (data?.list?.length > 0) {
				newArray = prodList.filter(item => {
					return !data.list.some(ele => {
						return item.storageUnitID === ele.storageUnitID
					})
				})
			} else {
				newArray = prodList
			}

			if (newArray && newArray.length > 0) {
				newArray.forEach(item => {
					const newProd = {
						availableQty: 0,
						certification: item.certification,
						inventoryType: [item.inventoryType],
						productID: item.id,
						storageUnitID: item.storageUnitID,
						supplyChainModel: [item.supplyChainModel],
						updatedAt: '',
					}
					finalList.push(newProd)
				})
			}
		}

		yield put(
			TradeDocDuc.creators.setDetailsBasedOnSupplyChainModel(finalList)
		)
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(TradeDocDuc.creators.setStorageTankLoadingStatus(false))
	}
}

function* checkIfSupplyChainIsEnforced() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('check-if-sc-enforced'))
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield CallWithRefreshCheck(requestUrl, options)
		const status = getIn(data, ['meta', 'enforceSupplyChainModel'])
		yield put(TradeDocDuc.creators.isSupplyChainModelEnforced(status))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-company-info'))
	}
}

function* fetchMirrorNodeData(action) {
	try {
		const { topicID, topicSequenceNumber } = action
		yield put(AppDuc.creators.showGlobalLoader('mirror-node-data'))
		const mirrorUrl = `${getMirrorNodeBaseUrl()}api/v1/topics/${topicID}/messages/${topicSequenceNumber}`
		const options = {
			method: 'GET',
			credentials: 'same-origin',
		}
		const mirrorData = yield call(request, mirrorUrl, options)

		if (
			mirrorData &&
			getIn(mirrorData, [
				'chunk_info',
				'initial_transaction_id',
				'transaction_valid_start',
			]) &&
			mirrorData.consensus_timestamp &&
			mirrorData.payer_account_id
		) {
			const validSplit = getIn(mirrorData, [
				'chunk_info',
				'initial_transaction_id',
				'transaction_valid_start',
			]).split('.')
			const validStartId = validSplit.join('-')

			const explorerUrl = `${getExplorerBaseurl()}transaction/${
				mirrorData.consensus_timestamp
			}?tid=${mirrorData.payer_account_id}-${validStartId}`

			window.open(explorerUrl, '_blank')
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('mirror-node-data'))
	}
}

function* uploadDocumentFile(action) {
	try {
		const { uploadPayload, successMsg } = action

		const requestUrl = `${getCoreEndPoint()}fileentities`

		const options = {
			method: 'POST',
			body: JSON.stringify(uploadPayload),
		}

		yield call(request, requestUrl, options)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: successMsg,
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('upload-document-file'))
	}
}

export default function* TradeDocManagerSaga() {
	try {
		yield all([
			takeLatest(
				TradeDocDuc.creators.fetchDashboardDocuments().type,
				fetchUserDocuments
			),
			takeLatest(
				TradeDocDuc.creators.changeDocumentStatus().type,
				changeDocumentStatus
			),
			takeLatest(
				TradeDocDuc.creators.fetchDashboardStats().type,
				fetchDashboardStatuses
			),
			takeLatest(
				TradeDocDuc.creators.fetchDocumentListing().type,
				fetchDocumentListing
			),
			takeLatest(
				TradeDocDuc.creators.initiateDocumentStep().type,
				initiateDocumentStep
			),
			takeLatest(
				TradeDocDuc.creators.initiateDocumentView().type,
				initiateDocumentView
			),
			takeLatest(
				TradeDocDuc.creators.fetchDocumentAttachments().type,
				fetchDocumentAttachments
			),
			takeLatest(
				TradeDocDuc.creators.fetchActiveDocument().type,
				fetchActiveDocument
			),
			takeLatest(TradeDocDuc.creators.fetchAProduct().type, fetchProduct),
			takeLatest(
				TradeDocDuc.creators.initiateDocumentSave().type,
				saveDocument
			),
			takeLatest(
				TradeDocDuc.creators.fetchParentSource().type,
				fetchParentSource
			),
			takeLatest(TradeDocDuc.creators.addNewRemark().type, addNewRemark),
			takeLatest(
				TradeDocDuc.creators.fetchTimelineData().type,
				fetchTimelineData
			),
			takeLatest(
				TradeDocDuc.creators.getDashboardActiveTDMRules().type,
				handleActiveTDMRulesFetch
			),
			takeLatest(
				TradeDocDuc.creators.fetchAuditReport().type,
				fetchAuditReport
			),
			takeLatest(
				TradeDocDuc.creators.appendAuditReport().type,
				appendAuditReport
			),
			takeLatest(
				TradeDocDuc.creators.saveAuditReportAsPDF().type,
				saveAuditReportAsPDF
			),
			takeLatest(
				TradeDocDuc.creators.fetchDealerGeneralReports().type,
				fetchDealerGeneralReports
			),
			takeLatest(
				TradeDocDuc.creators.createEntityAttachment().type,
				createEntityAttachment
			),
			takeLatest(
				TradeDocDuc.creators.updateEntityAttachment().type,
				updateEntityAttachment
			),
			takeLatest(
				TradeDocDuc.creators.deleteEntityAttachment().type,
				deleteEntityAttachment
			),
			takeLatest(
				TradeDocDuc.creators.deleteDraftEntity().type,
				deleteDraftEntity
			),
			takeLatest(
				TradeDocDuc.creators.fetchProductDetailsBasedOnSupplyChainModel()
					.type,
				fetchProductDetailsBasedOnSupplyChainModel
			),
			takeLatest(
				TradeDocDuc.creators.searchEntityRef().type,
				searchEntityRef
			),
			takeLatest(
				TradeDocDuc.creators.getHederaMessages().type,
				getHederaMessages
			),
			takeLatest(
				TradeDocDuc.creators.checkIfSupplyChainIsEnforced().type,
				checkIfSupplyChainIsEnforced
			),
			takeLatest(TradeDocDuc.creators.createWBSlip().type, createWBSlip),
			takeLatest(
				TradeDocDuc.creators.uploadDocumentFile().type,
				uploadDocumentFile
			),
			takeLatest(
				TradeDocDuc.creators.fetchMirrorNodeData().type,
				fetchMirrorNodeData
			),
			takeLatest(
				TradeDocDuc.creators.getDeforestationEvents().type,
				getDeforestationEvents
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
